import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogStyle = styled.div`
  width: 500px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 18px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 16px;
`;

const Label = styled.label`
  width: 120px;
  text-align: left;
`;

const InputField = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelectField = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 30px;
`;

const Button = styled.button`
  width: 90px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #649cdf;
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

  &.clear-btn {
    background-color: #979797;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
  &.clear-btn:hover {
    background-color: #858585;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
`;

const SearchDialog = ({ 
  onClose, 
  onSearch, 
  ownerList,
  patternList,
  initialFilters
}) => {

  const [filters, setFilters] = useState(initialFilters);

  const handleSearch = () => {
    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== '')
    );
    onSearch(validFilters);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prev) => ({ ...prev, [id]: value }));
  };  

  const handleOwnerInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prev) => ({ ...prev, [id]: value }));
    var patternCd = ''
    var pattern = patternList.filter((item)=>item.ownerCd==value)
    if(pattern.length) patternCd = pattern[0].patternCd
    setFilters((prev) => ({ ...prev, ['patternCd']: patternCd }));
  };  
  
  const handleClearAll = () => {
    const clearedFilters = {
      ownerCd: '1',
      patternCd: 'RETAIL',
      blockName: '',
      locationNum: '',
      shippingName: '',
      shippingCd: '',
      shippingKana: '',
    };
    setFilters(clearedFilters); // フィルタをリセット
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <DialogOverlay onClick={handleOverlayClick}>
      <DialogStyle>
        <Title><h2>絞り込み条件</h2></Title>
        <div>
          <FormRow>
            <Label htmlFor="ownerCd">荷主</Label>
            <SelectField
              id="ownerCd"
              value={filters.ownerCd}
              onChange={handleOwnerInputChange}
            >
              {ownerList.map(item => (
                <option key={'owner_'+item.ownerCd} value={item.ownerCd}>{item.ownerName}</option>
              ))}
            </SelectField>
          </FormRow>
          <FormRow>
            <Label htmlFor="patternCd">パターン名（コード）</Label>
            <SelectField
              id="patternCd"
              value={filters.patternCd}
              onChange={handleInputChange}
            >
              {patternList.filter((item)=>item.ownerCd==filters.ownerCd).map((item,index) => (
                  <option key={'owner_'+item.patternCd} value={item.patternCd} selected={index==1}>{item.patternName}（{item.patternCd}）</option>
              ))}
            </SelectField>
          </FormRow>
          {/*
          <FormRow>
            <Label htmlFor="blockName">ブロック名</Label>
            <InputField
              type="text"
              id="blockName"
              value={filters.blockName}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="locationNum">ロケーション番号</Label>
            <InputField
              type="text"
              id="locationNum"
              value={filters.locationNum}
              onChange={handleInputChange}
            />
          </FormRow>
          */}
          <FormRow>
            <Label htmlFor="shippingCd">出荷先コード</Label>
            <InputField
              type="text"
              id="shippingCd"
              value={filters.shippingCd}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="shippingName">出荷先名</Label>
            <InputField
              type="text"
              id="shippingName"
              value={filters.shippingName}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="shippingKana">出荷先カナ</Label>
            <InputField
              type="text"
              id="shippingKana"
              value={filters.shippingKana}
              onChange={handleInputChange}
            />
          </FormRow>
        </div>
        <ButtonContainer>
          <Button className="clear-btn" onClick={handleClearAll}>クリア</Button>
          <Button onClick={handleSearch}>検索</Button>
        </ButtonContainer>
      </DialogStyle>
    </DialogOverlay>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialFilters: PropTypes.object.isRequired,
  ownerList: PropTypes.array,
};

export default SearchDialog;
