import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import MediaHeaderDaily from './Atoms/MediaHeaderDaily'
import MediaDataDaily from './Atoms/MediaDataDaily'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  vertical-align: middle;
  height: 520px;
`

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 520px;
  padding:0.5rem;
`

export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`

const PerformanceDaily = (props) => {
  const {
    ownerList,
    handleupdate,
  } = props

  console.log('Received ownerList:', ownerList);
  console.log('Received handleupdate:', handleupdate);

  return (
    <ContentWrap>
      <BodyContent>
        <div style={{ width: '1837px', marginLeft: '10px', textAlign: 'left' }}>
          <MediaHeaderDaily />
          <ListUl width={ownerList.length >= 15 ? '1837px' : '1820px'} height={'673px'}>
            {ownerList.map((item) => (
              <ListLi key={`${item.baseId}-${item.ownerCd}`} style={{ cursor: 'default' }}>
                <MediaDataDaily
                  data={item}
                  handleupdate={handleupdate}
                />
              </ListLi>
            ))}
          </ListUl>
        </div>
      </BodyContent>
    </ContentWrap>
  )
};

PerformanceDaily.propTypes = {
  ownerList: PropTypes.array.isRequired,
  handleupdate: PropTypes.func.isRequired,
};

export default PerformanceDaily
