import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAlert } from 'react-alert';

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogStyle = styled.div`
  width: 500px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 18px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 16px;
`;

const Label = styled.label`
  width: 85px;
  text-align: left;
`;

const InputField = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 30px;
`;

const Button = styled.button`
  width: 90px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #649cdf;
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

  &.clear-btn {
    background-color: #979797;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
  &.clear-btn:hover {
    background-color: #858585;
  }
`;

const RegistrationDialog = ({ onClose, onRegister }) => {
  const [formData, setFormData] = useState({
    ownerCd: '',
    ownerName: '',
  });
  const alert = useAlert();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleRegister = async () => {
    const { ownerCd, ownerName } = formData;

    if (!ownerCd || !ownerName) {
      alert.error('荷主コードと荷主名を入力してください。');
      return;
    }

    try {
      const dataToRegister = {
        ...formData,
        baseId: 2, // baseIdを固定
      };
      console.log('Registering Data:', dataToRegister);
      // 登録処理を呼び出し
      await onRegister(dataToRegister);
      onClose(); // ダイアログを閉じる
    } catch (error) {
      console.error('登録処理中にエラーが発生しました:', error);
    }
  };

  const handleClearAll = () => {
    setFormData({
      ownerCd: '',
      ownerName: '',
    });
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <DialogOverlay onClick={handleOverlayClick}>
      <DialogStyle>
        <Title><h2>新規登録</h2></Title>
        <div>
          <FormRow>
            <Label htmlFor="ownerCd">荷主コード</Label>
            <InputField
              type="text"
              id="ownerCd"
              value={formData.ownerCd}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="ownerName">荷主名</Label>
            <InputField
              type="text"
              id="ownerName"
              value={formData.ownerName}
              onChange={handleInputChange}
            />
          </FormRow>
        </div>
        <ButtonContainer>
          <Button className="clear-btn" onClick={handleClearAll}>クリア</Button>
          <Button onClick={handleRegister}>登録</Button>
        </ButtonContainer>
      </DialogStyle>
    </DialogOverlay>
  );
};

RegistrationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
};

export default RegistrationDialog;