import React,{useState, useEffect, useRef} from 'react'
//import styled from 'styled-components'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../../Common/FunctionEnum'
import GRANT from '../../Common/GrantEnum'
import { PostApi } from '../../Common/ApiAxios'

import Select from '../../Common/Atoms/Select'
import SearchCalendar from '../../Common/Atoms/Calendar'
import RangeCalendar from '../../Common/Atoms/RangeCalendar'
import MonthCalendar from '../../Common/Atoms/MonthCalendar'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'

import IconCollapseImg from '../../../image/Collapse.png'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import IconCloseImg from '../../../image/close_icon.png'
import IconInquiryImg from '../../../image/inquiry-icon.png'

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
    searchDateRef,
    searchFromDateRef,
    searchToDateRef,
    searchMonthRef,
    onDateChange,
    onRangeDateChange,
    onMonthChange,
    onDateTypeChange,
    workplaceId,
    baseId
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);
  const isCainz = GRANT.CAINZ === loginUserInfo.grantNameId;
  const isAFS = GRANT.AFS === loginUserInfo.grantNameId;
  const [dateType, setDateType] = useState(0);
  const workplaceRef = useRef();
  const [isMasterOpen, setIsMasterOpen] = useState(false);
  const [workplaceList, setWorkplaceList] = useState([
      {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
    , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
    , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
    , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
    , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
  ]);
  const [rangeMonth, setRangeMonth] = useState({ fromDate: new Date(), toDate: new Date() });

  const toggleMasterMenu = () => {
    setIsMasterOpen(!isMasterOpen);
  };
  
  // 検索日タイプ変更イベント
  const changeDateTypeChange = (event) => {
    //console.log(event.target.value);

    setDateType(event.target.value);

    onDateTypeChange(event.target.value);
  }

  // 検索日付変更イベント
  const changeCalendar = (date)=>{
    searchDateRef.current = date;
    onDateChange(date);
  }

  // 検索期間変更イベント
  const changeRangeCalendar = (fromDate, toDate) => {
    searchFromDateRef.current = fromDate;
    searchToDateRef.current = toDate;
    onRangeDateChange(fromDate, toDate);
  }

  // 検索月変更イベント
  const changeMonthCalendar = (month) => {
    searchMonthRef.current = month;
    onMonthChange(month);
  }

  const onChangeWorkplace = (e) => {
    //console.log(workplaceRef.current.value);

    let id = workplaceRef.current.value;
    //onWorkplaceChange(id);

    let workplace = workplaceList.find(element => element.id === id);
    if (workplace) {
      history.push(workplace.url);
    }
  }

  async function topPage () {
    history.push('/top');
  }
  async function vasPerformance () {
    var state = {searchDate:searchDateRef.current,workplaceId:workplaceId}
    var pathname = '/logistic/vasPerformance'
    workplaceList.map((value)=>{
      if(value.id == workplaceId) pathname = value.url
    })
    history.push({state:state,pathname:pathname});
    //history.push({state:state,pathname:'/logistic/vasPerformance'});
    //history.push('/logistic/vasPersonal');
  }
  async function vasSummary () {
    var state = {searchDate:searchDateRef.current,workplaceId:workplaceId,baseId:baseId}
    history.push({state:state,pathname:'/logistic/vasSummary'});
    //history.push('/logistic/vasPersonal');
  }
  async function vasRealTime () {
    var state = {searchDate:searchDateRef.current,workplaceId:workplaceId,baseId:baseId}
    history.push({state:state,pathname:'/logistic/vasRealTimeProgress'});
    //history.push('/logistic/vasRealTimeProgress');
  }
  async function vasMasterOwner () {
    history.push({ pathname: '/logistic/vasMaster/owner' });
    // history.push('/logistic/vasRealTimeProgress');
  }
  async function vasMasterItem () {
    history.push({ pathname: '/logistic/vasMaster/item' });
    // history.push('/logistic/vasRealTimeProgress');
  }
  async function vasMasterStore () {
    history.push({ pathname: '/logistic/vasMaster/store' });
    // history.push('/logistic/vasRealTimeProgress');
  }
  async function vasMasterLocation () {
    history.push({ pathname: '/logistic/vasMaster/location' });
    // history.push('/logistic/vasRealTimeProgress');
  }
  async function vasPatternLocation () {
    history.push({ pathname: '/logistic/vasMaster/patternLocation' });
    // history.push('/logistic/vasRealTimeProgress');
  }
  useEffect(() => {
    if (isCainz) {
      setWorkplaceList([
        {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
      ]);
    } else if (isAFS) {
      setWorkplaceList(workplaceList.filter((elem) => { return elem.id == workplaceId}));
      // setWorkplaceList([
      //   {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
      // ]);
    } else {
      setWorkplaceList([
        {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
      , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
      , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
      , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
      , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
      ])
    }
  }, [isCainz, isAFS]);

  if (!grants && !subGrants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{'個人作業実績照会'}</div>
      <div style={{margin:'0 0px 0 0'}}>
        <Select selectRef={workplaceRef} onChange={onChangeWorkplace} width={'13rem'}>
          {workplaceList.map(item => (
            <option key={'work_'+item.id} value={item.id} selected={item.id==workplaceId}>{item.name}</option>
          ))}
        </Select>
      </div>
      <ul className='mainSearch'>
        <li>
          <div style={{margin:'0 8px 0 0'}}>
            <Select width={'6rem'} onChange={(e)=>changeDateTypeChange(e)}>
              <option key={'type_0'} value={0} selected={true}>{'日'}</option>
              <option key={'type_1'} value={1} >{'期間'}</option>
              <option key={'type_2'} value={2} >{'月'}</option>
            </Select>
          </div>
          {dateType == 0 && 
            <>
            <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'対象日付'}</div>
            <div style={{margin:'0 0 0 0'}}>
              <SearchCalendar changeCalendar={changeCalendar} dataValue={searchDateRef.current} isViewIcon/>
            </div>
            </>
          }
          {dateType == 1 &&
            <>
            <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'対象期間'}</div>
            <div style={{margin:'0 0 0 0', display:'flex'}}>
              <RangeCalendar onChange={changeRangeCalendar} 
                value={{ fromDate:new Date(searchFromDateRef.current), toDate:new Date(searchToDateRef.current) }}
                isViewIcon placeholderStart={'開始日'} placeholderEnd={'終了日'}/>
            </div>
            </>
          }
          {dateType == 2 &&
          <>
          <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'対象年月'}</div>
          <div style={{margin:'0 0 0 0', display:'flex'}}>
            <MonthCalendar changeCalendar={changeMonthCalendar} dataValue={searchMonthRef.current} isViewIcon placeholder={'対象年月'}/>
          </div>
          </>}
        </li>
        <li className='subSearch'>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
      <FunctionContent 
          onMouseLeave={() => {
            onCloseFunction();
            setIsMasterOpen(false);
          }}
        >
          {!isExternalUser &&
          <>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          </>
          }
          <FunctionContentRow onClick={vasSummary} style={{marginTop:'12px'}}>
            <FunctionIcon alt='VAS運用状況' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>VAS運用状況</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow onClick={vasRealTime} style={{marginTop:'12px'}}>
            <FunctionIcon alt='作業工程実績照会' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>作業工程実績照会</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow onClick={vasPerformance} style={{marginTop:'12px'}}>
            <FunctionIcon alt='種まきデータ実績照会' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>種まきデータ実績照会</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px'}}>
            <FunctionIcon alt='個人作業実績照会' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>個人作業実績照会</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow onClick={toggleMasterMenu} style={{ marginTop: '12px', marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
            <FunctionIcon alt='マスタ' src={IconInquiryImg} />
            <FunctionName><span style={{ marginLeft: '0.5rem' }}>マスタ</span></FunctionName>
            <span style={{ marginLeft: '12px' }}>{isMasterOpen ? '▲' : '▼'}</span>
          </FunctionContentRow>
          {isMasterOpen && (
            <>
            <FunctionContentRow onClick={vasMasterOwner} style={{ marginTop: '12px', marginLeft: '1rem' }}>
              <FunctionIcon alt="荷主マスタ" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>荷主マスタ</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasMasterItem} style={{ marginTop: '12px', marginLeft: '1rem' }}>
              <FunctionIcon alt="製品マスタ" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>製品マスタ</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasMasterStore} style={{ marginTop: '12px', marginLeft: '1rem' }}>
              <FunctionIcon alt="店舗マスタ" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>店舗マスタ</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow  onClick={vasMasterLocation} style={{ marginTop: '12px', marginLeft: '1rem' }}>
              <FunctionIcon alt="ロケ一覧" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>ロケ一覧</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasPatternLocation} style={{ marginTop: '12px', marginLeft: '1rem', marginBottom: '12px' }}>
              <FunctionIcon alt="パターンロケ一覧" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>パターンロケ一覧</span></FunctionName>
            </FunctionContentRow>
          </>
          )}
        </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search
