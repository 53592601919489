import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { GetApi, PutApi } from '../../Common/ApiAxios';
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice';
import { BoardBaseFormRow, BoardBaseFormRowScroll } from '../Wrap/BoardBaseWrap';
import Search from './Search';
import SideSubMenu from '../SideSubMenu';
import PerformanceDaily from './PerformanceDaily';
import { ExportCSV } from './Atoms/Export';
import IconDownloadImg from '../../../image/down-load-csv-icon.png';

const DefaultUrl = '/api/masterManagement/itemMaster';

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const ItemCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 60px;
  font-size: 18px;

  img {
    width: 17px;
    height: 17px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const VASMasterItem = () => {
  const { loginUserInfo } = useSelector(state => state);
  const dispatch = useDispatch();
  const alert = useAlert();
  const searchDateRef = useRef(null);
  const [viewFunction, setViewFunction] = useState(false);
  const [itemMasterList, setItemMasterList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Handlers
  const onClickFunction = () => setViewFunction(!viewFunction);
  const onCloseFunction = () => setViewFunction(false);

  // Fetch data functions
  const getItemMaster = async (isLoadSpinner) => {
    if (isLoadSpinner) dispatch(SetLoadSpinner(true));
    
    const apiUrl = `${DefaultUrl}/getItemMaster`;
  
    const result = await GetApi(apiUrl);
    if (isLoadSpinner) dispatch(SetLoadSpinner(false));
  
    if (result.errorDetail) {
      alert.error('該当データがありません（製品マスタの取得）');
      setItemMasterList([]);
      setFilteredData([]);
    } else {
      // id と quantityInSuntenor を Int 型に変換
      const transformedData = result.data.map(item => {
        const id = parseInt(item.id, 10);
        const quantityInSuntenor = item.quantityInSuntenor != null && !isNaN(parseInt(item.quantityInSuntenor, 11))
          ? parseInt(item.quantityInSuntenor, 11)
          : 0;

        return {
          ...item,
          id,
          quantityInSuntenor,
        };
      });
  
      setItemMasterList(transformedData);
      setFilteredData(transformedData);
    }
  };
  
  const updateSuntenorQuantity = async (id, formData) => {
    const apiUrl = `${DefaultUrl}/${id}`;
    
    // ロードスピナーを表示
    dispatch(SetLoadSpinner(true));
  
    try {
      console.log("Updating Suntenor Quantity at URL:", apiUrl);
      console.log("Sending data to server:", formData);
  
      // サーバーにリクエストを送信
      const result = await PutApi(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      console.log("Result:", result);
  
      if (result && result.status === 200) {
        console.log("Update Success:", result.data);
        alert.success("サンテナ入数が更新されました");
      } else {
        console.error("Update Failed:", result.statusText || "不明なエラー");
        alert.error("サンテナ入数の更新に失敗しました");
      }
    } catch (error) {
      console.error("Update Error:", error.response || error.message);
      alert.error("サーバーエラーが発生しました");
    } finally {
      // ロードスピナーを非表示
      dispatch(SetLoadSpinner(false));
    }
  };  

  const handleSearch = (filters) => {
    const safeFilters = filters || {};

    if (Object.values(safeFilters).every(value => !value)) {
      setFilteredData(itemMasterList);
      return;
    }

    const filtered = itemMasterList.filter(item => {
      return (
        (!filters.itemNo || item.itemNo?.toString().includes(filters.itemNo)) &&
        (!filters.itemName || item.itemName?.toString().includes(filters.itemName)) &&
        (!filters.itemKana || item.itemKana?.toString().includes(filters.itemKana)) &&
        (!filters.pcrsDivision ||
          (filters.pcrsDivision === 'PC' && Number(item.pcrsDivision) === 0) ||
          (filters.pcrsDivision === 'RS' && Number(item.pcrsDivision) === 1)) &&
        (!filters.janCode || item.janCode?.toString().includes(filters.janCode))
      );
    });

    setFilteredData(filtered);
  };

  useEffect(() => {
    getItemMaster(true);
  }, []);

  return (
    <>
      <Search
        searchDateRef={searchDateRef}
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        onSearch={handleSearch}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true} />
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <ItemCount>
              <img src={IconDownloadImg} alt="CSV出力" onClick={() => ExportCSV(filteredData)} />
              {filteredData.length} 件
            </ItemCount>
            <PerformanceDaily
              personalList={filteredData}
              updateSuntenorQuantity={updateSuntenorQuantity}
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  );
};

export default VASMasterItem;
