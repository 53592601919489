import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { GetApi, PutApi } from '../../Common/ApiAxios';
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice';
import { BoardBaseFormRow, BoardBaseFormRowScroll } from '../Wrap/BoardBaseWrap';
import { MultipartApi } from '../../Common/ApiAxios';
import Search from './Search';
import SideSubMenu from '../SideSubMenu';
import PerformanceDaily from './PerformanceDaily';
import { ExportCSV } from './Atoms/Export';
import IconDownloadImg from '../../../image/down-load-csv-icon.png';

const DefaultUrl = '/api/masterManagement/ownerMaster';

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const ItemCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 60px;
  font-size: 18px;

  img {
    width: 17px;
    height: 17px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const VASMasterOwner = () => {
  const { loginUserInfo } = useSelector(state => state);
  const dispatch = useDispatch();
  const alert = useAlert();
  const searchDateRef = useRef(null);
  const [viewFunction, setViewFunction] = useState(false);
  const [ownerList, setOwnerList] = useState([]);
  const history = useLocation(); 
  const [filteredData, setFilteredData] = useState([]);

  // Handlers
  const onClickFunction = () => setViewFunction(!viewFunction);
  const onCloseFunction = () => setViewFunction(false);

  const baseId = history.state&&history.state.baseId?history.state.baseId:2

  // 荷主一覧の取得
  const getVasOwner = async (isLoadSplinner) => {
    if (isLoadSplinner) dispatch(SetLoadSpinner(true));
  
    const apiUrl = `${DefaultUrl}/owner/${baseId}`;
    console.log(apiUrl);
    const result = await GetApi(apiUrl);
  
    if (isLoadSplinner) dispatch(SetLoadSpinner(false));
  
    if (result && result.data && Array.isArray(result.data)) {
      // baseId を各データに追加
      const dataWithBaseId = result.data.map((item) => ({
        ...item,
        baseId,
      }));
      setOwnerList(dataWithBaseId);
      setFilteredData(dataWithBaseId);
    } else {
      alert.error('条件の該当データがありません(荷主一覧)');
      setOwnerList([]);
      setFilteredData([]);
    }
  };  

  // 荷主マスタ更新処理
  const handleupdate = async (updateParams) => {

    const apiUrl = `${DefaultUrl}/update`; // 固定のAPI URLを定義
    console.log('handleupdate called with:', { apiUrl, updateParams });

    const submitData = new window.FormData();
    submitData.append('formData', new window.Blob([JSON.stringify(updateParams)], { type: 'application/json' }));
  
    try {
      dispatch(SetLoadSpinner(true));

      const result = await MultipartApi(apiUrl, 'PUT', submitData, updateParams);

      dispatch(SetLoadSpinner(false));

      if (result.errorDetail) {
        alert.error('更新に失敗しました。');
      } else {
        alert.success('更新に成功しました。');
        await getVasOwner(false);// 一覧を再取得
      }
    } catch (error) {
      console.error('更新中にエラーが発生しました:', error);
      alert.error('更新処理中にエラーが発生しました。');
      dispatch(SetLoadSpinner(false));
    }
  };

  // 荷主マスタ新規登録処理
  const handleInsert = async (formData) => {
    console.log('handleInsert called with:', formData);
  
    // 重複確認
    const isDuplicate = ownerList.some((owner) => owner.ownerCd === formData.ownerCd);
    if (isDuplicate) {
      alert.error('指定された荷主コードは既に存在しています。');
      return;
    }
  
    const apiUrl = `${DefaultUrl}/insert`;
    const submitData = new window.FormData();
    submitData.append('formData', new Blob([JSON.stringify(formData)], { type: 'application/json' }));
  
    try {
      dispatch(SetLoadSpinner(true));

      const result = await MultipartApi(apiUrl, 'POST', submitData);
  
      dispatch(SetLoadSpinner(false));
  
      if (result.errorDetail) {
        alert.error('新規登録に失敗しました。');
      } else {
        alert.success('新規登録に成功しました。');
        await getVasOwner(false); // 一覧を再取得
      }
    } catch (error) {
      console.error('新規登録中にエラーが発生しました:', error);
      alert.error('新規登録処理中にエラーが発生しました。');
      dispatch(SetLoadSpinner(false));
    }
  };  
  
  useEffect(() => {
    getVasOwner(true);
  }, []);

  const handleSearch = (filters) => {
    const safeFilters = filters || {};
  
    if (!filters || Object.values(safeFilters).every(value => !value)) {
      setFilteredData(ownerList);
      return;
    }
  
    const filtered = ownerList.filter(item => {
      return (
        (!filters.ownerCd || item.ownerCd?.toString().includes(filters.ownerCd)) &&
        (!filters.ownerName || item.ownerName?.toString().includes(filters.ownerName))
      );
    });
  
    setFilteredData(filtered);
  };

  return (
    <>
      <Search
        searchDateRef={searchDateRef}
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        onSearch={handleSearch}
        onRegister={handleInsert}
        baseId={baseId}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true} />
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <ItemCount>
              <img src={IconDownloadImg} alt="CSV出力" onClick={() => ExportCSV(filteredData)} />
              {filteredData.length} 件
            </ItemCount>
            <PerformanceDaily
              ownerList={filteredData}
              handleupdate={(apiUrl, updateParams) => handleupdate(apiUrl, updateParams)}
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  );
};

export default VASMasterOwner;
