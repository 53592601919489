import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useAlert } from 'react-alert';

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import editIcon from '../../../../image/edit-icon.png'; 

const MediaWrap = styled.div`
  width:1837px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  text-align: center;
  align-items: center;
`

const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 3rem;
  cursor: pointer;

  img {
    width: 20px; /* アイコンの幅 */
    height: 20px; /* アイコンの高さ */
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1); /* ホバー時に拡大 */
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 2rem;
  gap: 20px;


  button {
    width: 96px;
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    background-color: #649cdf;
    color: #fff;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

    &.delete {
        background-color: #979797;
        box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
      &:hover {
        background-color: #858585;
        box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
      }
    }
  }
`;

const EditableInput = styled.input`
  flex: 1;
  width: 210px;
  margin: 2px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
`;

const EditableInput2 = styled.input`
  flex: 1;
  width: 1160px;
  margin: 2px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
`;

const MediaDataDaily = (props) => {
  const {
    data,
    handleupdate,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editedOwnerName, setEditedOwnerName] = useState(data.ownerName);

  const alert = useAlert();

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdate = async () => {
    const updateParams = {
      baseId: data.baseId,
      ownerCd: data.ownerCd,
      ownerName: editedOwnerName,
    };

    console.log('Updating with params:', updateParams);

    try {
      await handleupdate(updateParams); // 更新処理を呼び出す
      setIsEditing(false); // 更新後、編集モードを解除する
    } catch (error) {
      console.error('更新処理中にエラーが発生しました:', error);
      alert.error('更新処理中にエラーが発生しました。');
    }
  };

  const handleCancel = () => {
    setEditedOwnerName(data.ownerName);
    setIsEditing(false);
  };

  if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'250px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.ownerCd}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        {isEditing ? (
          <EditableInput2
            type="text"
            value={editedOwnerName}
            onChange={(e) => setEditedOwnerName(e.target.value)}
          />
        ) : (
          <TextWrap style={{ width: '1200px', textAlign: 'left' }}>
            <div style={{ marginLeft: '1rem' }}>{data.ownerName}</div>
          </TextWrap>
        )}
      </MediaItem>
      {isEditing ? (
        <ButtonContainer>
          <button className="update" type="button" onClick={handleUpdate}>更新</button>
          <button className="delete" type="button" onClick={handleCancel}>キャンセル</button>
        </ButtonContainer>
      ) : (
        <IconContainer onClick={handleEdit}>
          <img src={editIcon} alt="編集" />
        </IconContainer>
      )}
    </MediaWrap>
  )
}

MediaDataDaily.propTypes = {
  data: PropTypes.object.isRequired,
  handleupdate: PropTypes.func.isRequired,
}

export default MediaDataDaily
