import React, { useState, useEffect,useRef,useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'

import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

import Search from './Search'
import LocationTableBoard from './LocationTableBoard'

import { MultipartApi } from '../../Common/ApiAxios';
import SideSubMenu from '../SideSubMenu'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

const DefaultUrl = '/api/masterManagement/patternLocation'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const VASPatternLocationMaster = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const [viewSubSearch,setViewSubSearch] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [storeList, setStoreList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [locationOrgList, setLocationOrgList] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [patternList, setPatternList] = useState([]);
  const searchDateRef = useRef()

  const searchWorkplaceRef = useRef();
  const history = useLocation(); 
  const hisState = history.state

  /*2023/01/29 K.Harada ADD 現場IDを表示するように変更 */
  const workplaceId = history.state&&history.state.workplaceId?history.state.workplaceId:'000006'
  const baseId = history.state&&history.state.baseId?history.state.baseId:2
  //フィルターを定義
  const [filters, setFilters] = useState({
    ownerCd: '1',
    patternCd: 'RETAIL',
    blockName: '',
    locationNum: '',
    shippingName: '',
    shippingCd: '',
    shippingKana: '',
   });

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants]);

  const onFilter = useCallback((list,newFilters) => {
    console.log(list)
    if(!list) return
    return list.filter((item)=>{
      item.patternLocationInfo =
      item.patternLocationInfo.filter((info)=>{
        var blockNameFg = !newFilters.blockName || info?.blockName?.toString().includes(newFilters.blockName)
        var locationNumFg = !newFilters.locationNum || info?.locationNum?.toString().includes(newFilters.locationNum)
        var shippingFg = !newFilters.shippingName || info?.shippingName?.toString().includes(newFilters.shippingName)
        var shippingCdFg = !newFilters.shippingCd || info?.shippingCd?.toString().includes(newFilters.shippingCd)
        var shippingKanaFg = !newFilters.shippingKana || info?.shippingKana?.toString().includes(newFilters.shippingKana)
        return !!locationNumFg && !!shippingFg && !!blockNameFg && !!shippingCdFg && !!shippingKanaFg
      })
     //console.log(item.sort, item.patternLocationInfo.length)
     return item.patternLocationInfo.length
    })
  }, [])

  const onWorkplaceId=(id)=>{
    searchWorkplaceRef.current = id;

    getVasLocation(true);
  }
    
  // パターンロケ一覧の取得
  const getVasPatternLocation = async (isLoadSplinner,newFilters) => {
    //console.log('call getVasPersonalDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = `${DefaultUrl}/${baseId}/${newFilters.ownerCd}/${newFilters.patternCd}`;
    
    console.log(apiUrl)
    const result = await GetApi(apiUrl);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('条件の該当データがありません(パターンロケ一覧)');
      setLocationOrgList([])   
      setLocationList([])    
    } else {
      console.log(result);
      setLocationOrgList(result.data)   
      setLocationList(onFilter(result.data,newFilters))    
    }
  }
  // ロケ一覧の取得
  const getVasLocation = async (isLoadSplinner) => {
    //console.log('call getVasPersonalDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = `${DefaultUrl}/${baseId}`;
    
    console.log(apiUrl)
    const result = await GetApi(apiUrl);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('条件の該当データがありません(ロケ一覧)');
      setLocationOrgList([]);
      setLocationList([]);
    } else {
      console.log(result);
      setLocationOrgList(result.data)   
      setLocationList(result.data)    
    }
  }
  // 荷主一覧の取得
  const getVasOwner = async (isLoadSplinner) => {
    //console.log('call getVasPersonalDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = `${DefaultUrl}/owner/${baseId}`;
    
    console.log(apiUrl)
    const result = await GetApi(apiUrl);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('条件の該当データがありません(荷主一覧)');
      setOwnerList([]);
    } else {
      console.log(result);
      setOwnerList(result.data);   
    }
  }
  // パターン一覧の取得
  const getVasPattern = async (isLoadSplinner) => {
    //console.log('call getVasPersonalDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = `${DefaultUrl}/pattern/${baseId}`;
    
    console.log(apiUrl)
    const result = await GetApi(apiUrl);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('条件の該当データがありません(パターン一覧)');
      setPatternList([]);
    } else {
      console.log(result);
      setPatternList(result.data);   
    }
  }
  // 描画時に一度だけ実行
  useEffect(() => {
    //getVasLocation(true);
    getVasOwner(false);
    getVasPattern(false);
    getLocation(false,{ownerCd:1,patternCd:'RETAIL'});
    getVasPatternLocation(true,{ownerCd:1,patternCd:'RETAIL'});
  }, [])
  // `Update` パターンの登録・更新処理
  const handleUpdatePattern =  async(param) => {
    if(!param.patternCd){
      alert.error('パターンコードがブランクです、入力をお願いします。');
      return
    }
    if(!param.patternName){
      alert.error('パターン名がブランクです、入力をお願いします。');
      return
    }
    if(!param.patternKana){
      alert.error('パターン名カナがブランクです、入力をお願いします。');
      return
    }
    var param1 =
    {
      baseId: baseId,
      ownerCd: param.ownerCd,
      patternCd: param.patternCd,
      patternName: param.patternName,
      patternKana: param.patternKana,
      blockName: 'イチ',
      blockKana: 'イチ',
      cooperationFlag: param.cooperationFlag
    }
    dispatch(SetLoadSpinner(true));
    const apiUrl = `${DefaultUrl}/upsert/pattern`;
    console.log(apiUrl)
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(param1)], { type: 'application/json' }))
    const result = await MultipartApi(apiUrl,'PUT',submitData,param1);
    console.log(result)
    dispatch(SetLoadSpinner(false));
    if(result.errorDetail)
    {
      alert.error('パターンマスタの更新に失敗しました。');
    }else{
      alert.info('パターンマスタの更新に成功しました。');
      getVasPattern(true);
    }
  };

  const getLocation = async (isLoadSpinner,param) => {
    if (isLoadSpinner) dispatch(SetLoadSpinner(true));

    const apiUrl = `${DefaultUrl}/location/${baseId}/${param.ownerCd}/${param.patternCd}`;
    console.log(apiUrl)

    const result = await GetApi(apiUrl);
    if (isLoadSpinner) dispatch(SetLoadSpinner(false));
    console.log(result)
    if (result.errorDetail) {
      alert.error('該当データがありません（ロケ一覧の取得）');
      setStoreList([]);
    } else {
      setStoreList(result.data.map((r)=>{return r.locationNum}));
    }
  };

  
  if (!searchDateRef.current) {
    if(hisState && hisState.searchDate){
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    }else{
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    }   
  }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        viewSubSearch={viewSubSearch}
        onWorkplaceChange={(id)=>onWorkplaceId(id)}
        onClickOption={(view)=>setViewSubSearch(view)}
        workplaceId={workplaceId}
        baseId={baseId}
        ownerList={ownerList}
        patternList={patternList}
        onFilter={(newFilters)=>{getVasPatternLocation(true,newFilters)}}
        handleUpdatePattern={(param)=>handleUpdatePattern(param)}
        filters={filters} 
        setFilters={setFilters}
        searchDateRef={searchDateRef}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <LocationTableBoard 
              locationList={locationList}
              locationOrgList={locationOrgList}
              onReSerach={()=>{
                getLocation(false,filters)
                getVasPatternLocation(true,filters)
              }} 
              storeList={storeList}
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default VASPatternLocationMaster
