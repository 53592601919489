import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { GetApi } from '../../Common/ApiAxios';
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice';
import { BoardBaseFormRow, BoardBaseFormRowScroll } from '../Wrap/BoardBaseWrap';
import Search from './Search';
import SideSubMenu from '../SideSubMenu';
import PerformanceDaily from './PerformanceDaily';
import { ExportCSV } from './Atoms/Export';
import IconDownloadImg from '../../../image/down-load-csv-icon.png';

const DefaultUrl = '/api/masterManagement/storeMaster';

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const ItemCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 60px;
  font-size: 18px;

  img {
    width: 17px;
    height: 17px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const VASMasterStore = () => {
  const { loginUserInfo } = useSelector(state => state);
  const dispatch = useDispatch();
  const alert = useAlert();
  const searchDateRef = useRef(null);
  const [viewFunction, setViewFunction] = useState(false);
  const [storeMasterList, setStoreMasterList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Handlers
  const onClickFunction = () => setViewFunction(!viewFunction);
  const onCloseFunction = () => setViewFunction(false);

  // Fetch data functions
  const getStoreMaster = async (isLoadSpinner) => {
    if (isLoadSpinner) dispatch(SetLoadSpinner(true));

    const apiUrl = `${DefaultUrl}/getStoreMaster`;

    const result = await GetApi(apiUrl);
    if (isLoadSpinner) dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('該当データがありません（店舗マスタの取得）');
      setStoreMasterList([]);
      setFilteredData([]); // データがない場合もリセット
    } else {
      setStoreMasterList(result.data);
      setFilteredData(result.data); // 初期状態では全データを表示
    }
  };

  // `Search` コンポーネントから渡される検索条件を基にデータをフィルタリング
  const handleSearch = (filters) => {
    const safeFilters = filters || {};

    if (Object.values(safeFilters).every(value => !value)) {
      // 全条件が空の場合、全データを表示
      setFilteredData(storeMasterList);
      return;
    }

    const filtered = storeMasterList.filter(item => {
      return (
        (!filters.companyCode || item.companyCode?.toString().includes(filters.companyCode)) &&
        (!filters.storeCode || item.storeCode?.toString().includes(filters.storeCode)) &&
        (!filters.storeName || item.storeName?.toString().includes(filters.storeName)) &&
        (!filters.nohinCenterName || item.nohinCenterName?.toString().includes(filters.nohinCenterName)) &&
        (!filters.ownerName || item.ownerName?.toString().includes(filters.ownerName)) &&
        (!filters.locationCd || item.locationCd?.includes(filters.locationCd)) &&
        (!filters.patternCd || 
          (filters.patternCd === '登録有' && item.patternCd) || 
          (filters.patternCd === '登録無' && !item.patternCd))
      );
    });

    setFilteredData(filtered);
  };

  useEffect(() => {
    getStoreMaster(true);
  }, []);

  return (
    <>
      <Search
        searchDateRef={searchDateRef}
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        onSearch={handleSearch}
      />

      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true} />
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <ItemCount>
              <img src={IconDownloadImg} alt="CSV出力" onClick={() => ExportCSV(filteredData)} />
              {filteredData.length} 件
            </ItemCount>
            <PerformanceDaily
              personalList={filteredData}
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  );
};

export default VASMasterStore;
